<template>
  <div class="main-box">
    <el-table
            :data="tableData"
            ref="rfTable"
            size="mini">
      <el-table-column
              prop="title"
              label="名称">
      </el-table-column>
      <el-table-column
              prop="mchid"
              label="商户号"
              align="center"
              width="200">
      </el-table-column>
      <el-table-column
              prop="utime"
              label="更新时间"
              align="center"
              width="140">
      </el-table-column>
      <el-table-column
              fixed="right"
              label="操作"
              align="center"
              width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editGroup(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
        title="编辑配置"
        :visible.sync="showModal"
        width="700px">
          <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="140px" size="small">
            <el-form-item label="标题" prop="title">
              <el-input v-model="info.title" placeholder="请输入标题"></el-input>
            </el-form-item>
            <el-form-item label="商户号" prop="mchid" v-if="info.type==1">
              <el-input v-model="info.mchid" placeholder="请输入商户号"></el-input>
            </el-form-item>
            <el-form-item label="商户v2密钥" prop="key" v-if="info.type==1">
              <el-input v-model="info.key" placeholder="请输入密钥"></el-input>
            </el-form-item>
            <el-form-item label="商户v3密钥" prop="key" v-if="info.type==1">
              <el-input v-model="info.key3" placeholder="请输入密钥"></el-input>
            </el-form-item>
            <el-form-item label="apiclient_cert.pem" prop="apiclient_cert" v-if="info.type==1">
              <span style="display: inline-block">
                <cert-upload :extend="extend" :callFunc="function (data) { info.apiclient_cert=data.url;info.apiclient_cert_title=data.title }"></cert-upload>
              </span>
              <span v-if="info.apiclient_cert" class="mg-lf-10">已上传</span>
            </el-form-item>
            <el-form-item label="apiclient_key.pem" prop="apiclient_key" v-if="info.type==1">
              <span style="display: inline-block">
                <cert-upload :extend="extend" :callFunc="function (data) { info.apiclient_key=data.url;info.apiclient_key_title=data.title }"></cert-upload>
              </span>
              <span v-if="info.apiclient_key" class="mg-lf-10">已上传</span>
            </el-form-item>
            <el-form-item label="支付宝开放平台appid" prop="mchid2" v-if="info.type==2">
              <el-input v-model="info.mchid" placeholder="请输入appid"></el-input>
            </el-form-item>
            <el-form-item label="支付宝公钥" prop="public_key" v-if="info.type==2">
              <el-input type="textarea" rows="7" v-model="info.public_key" placeholder="请输入公钥"></el-input>
            </el-form-item>
            <el-form-item label="商户私钥" prop="private_key" v-if="info.type==2">
              <el-input type="textarea" rows="10" v-model="info.private_key" placeholder="请输入私钥"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="save">保存</el-button>
            </el-form-item>
          </el-form>
    </el-dialog>
  </div>
</template>
<script>

import CertUpload from "@/components/CertUpload";
export default {
  components: {
    CertUpload
  },
  data() {
    return {
      showModal: false,
      info: {
        id: '',
        type: 1,
        title: '',
        mchid: '',
        key: '',
        key3: '',
        apiclient_cert: '',
        apiclient_cert_title: '',
        apiclient_key: '',
        apiclient_key_title: '',
        public_key: '',
        private_key: ''
      },
      extend: ['pem'],
      tableData: [],
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        mchid: [
          { required: true, message: '请输入商户号', trigger: 'blur' }
        ],
        key: [
          { required: true, message: '请选择密钥KEY', trigger: 'blur' }
        ],
        key3: [
          { required: true, message: '请选择密钥KEY', trigger: 'blur' }
        ],
        apiclient_cert: [
          { required: true, message: '请上传证书', trigger: 'blur' }
        ],
        apiclient_key: [
          { required: true, message: '请上传证书', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    getList() {
      var that = this
      that.showLoading()
      this.$api.merchant.paymentIndex({}, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.tableData = res.data
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    editGroup(item) {
      this.info.id = item.id
      this.info.type = item.type
      this.info.title = item.title
      this.info.mchid = item.mchid
      if(item.config) {
        if(item.config.key) {
          this.info.key = item.config.key
        }
        if(item.config.key3) {
          this.info.key3 = item.config.key3
        }
        if(item.config.public_key) {
          this.info.public_key = item.config.public_key
        }
        if(item.config.private_key) {
          this.info.private_key = item.config.private_key
        }
        if(item.config.apiclient_cert) {
          this.info.apiclient_cert = item.config.apiclient_cert
        }
        if(item.config.apiclient_key) {
          this.info.apiclient_key = item.config.apiclient_key
        }
      }
      this.showModal = true
    },
    save() {
      var _this = this
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          var param = _this.info
          this.$api.merchant.paymentEdit(param,res => {
            if(res.errcode == 0) {
              _this.showModal = false
              _this.success(res.errmsg)
              _this.getList()
            } else {
              _this.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style scoped>
</style>
