<template>
  <div class="">
    <el-upload
        ref="upload"
        :action="uploadUrl"
        name="file"
        :data="picdata"
        :show-file-list="false"
        :multiple="false"
        :before-upload="beforeUpload"
        :on-success="uploadSuccess"
        :on-error="uploadError" style="margin-top: -5px">
      <el-button icon="el-icon-upload2" size="small" type="primary">上传文件</el-button>
    </el-upload>
  </div>
</template>

<script>
  import config from "../config/config";
  import {getToken} from "@/utils/auth";

  export default {
    data() {
      return {
        uploadUrl: config.HTTP_REQUEST_URL + '/shop/common/uploadcert?token=' + getToken(),
        picdata: {
          type: 'file'
        }
      }
    },
    props: {
      extend: Array,
      callFunc: Function
    },
    created() {
    },
    mounted() {
    },
    methods: {
      beforeUpload(file) {
        var defaultExt = ['jpg','png','gif']
        var extend = file.name.substring(file.name.lastIndexOf(".")+1).toLocaleLowerCase()
        if(this.extend) {
          if(this.extend.indexOf(extend) == -1) {
            this.$message.error('上传文件格式不正确!');
            return false
          }
        } else if(defaultExt.indexOf(extend) == -1) {
          this.$message.error('上传文件格式不正确!');
          return false
        }
        const isLt2M = file.size / 1024 / 1024 < 1;
        if (!isLt2M) {
          this.$message.error('上传文件大小不能超过 1MB!');
        }
        return isLt2M;
      },
      uploadSuccess(res, file) {
        if(res.errcode==0) {
          this.success('上传成功')
          this.callFunc(res.data)
        } else {
          this.fail(res.errmsg)
        }
      },
      uploadError(res, file) {
        this.$message.error('上传失败，请重新上传!');
      }
    }
  }
</script>
<style scoped>
</style>
